import React, { ReactNode } from "react"

import Helmet from "react-helmet"

import { Row, Col } from "antd"
import "antd/dist/antd.css"
import * as styles from "./index.module.scss"

import { Ipage } from "@src/pages"

interface Ititle {
  children: string | ReactNode
}
function Title({ children }: Ititle) {
  return <div className={styles.title}>{children}</div>
}

interface IchromIntro {
  img: any
  intros: string[]
}
function ChromeIntro({ img, intros }: IchromIntro) {
  return (
    <div className={styles.chrome_intro}>
      <div>
        <img src={img} alt="img" />
      </div>
      <ul>
        {intros.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  )
}
interface IrenderItem {
  img: any
  text_title: string
  text_intro: string
}
function RenderItem({ img, text_title, text_intro }: IrenderItem) {
  return (
    <div className={styles.render_item}>
      <div className={styles.img_container}>
        <img src={img} alt="this is a image" />
      </div>
      <div className={styles.text_container}>
        <div className={styles.text_title}>{text_title}</div>
        <div className={styles.text_intro}>{text_intro}</div>
      </div>
    </div>
  )
}

function Line() {
  return <div className={styles.line}></div>
}

interface IflexBox {
  children: string | ReactNode[] | ReactNode
}
function FlexBox({ children }: IflexBox) {
  return <div className={styles.flex_box}>{children}</div>
}

function getImg(imageName: string) {
  let res = require(`@images/marketing_force/${imageName}.png`)
  return res.default
}
const facebookData = [
  {
    img: "facebookBg1",
    text_title: "照片广告",
    text_intro:
      "提供丰富免费的素材库供使用，几分钟完成创建，利用照片广告激起受众兴趣。",
  },
  {
    img: "facebookBg2",
    text_title: "幻灯片广告",
    text_intro:
      "使用多张静态图片，以及能够显示动作、播放声音、展示文字的效果，以此来展示你的广告内容。",
  },
  {
    img: "facebookBg3",
    text_title: "轮播广告",
    text_intro: "单条广告中以多图呈现，丰富品牌视觉元素，激发用户共鸣。",
  },
  {
    img: "facebookBg4",
    text_title: "视频广告",
    text_intro:
      "通过动态图像抓住用户注意力，展示商品不同特点，传递生动价值信息。",
  },
  {
    img: "facebookBg5",
    text_title: "精品栏广告",
    text_intro:
      "以图像为载体打造沉浸式体验，让消费者轻松发现、浏览和购买商品。",
  },
  {
    img: "facebookBg6",
    text_title: "快拍广告",
    text_intro:
      "显示于动态顶部，利用移动设备全屏界面，在沉浸式环境中与用户交流互动。",
  },
]

const googleData = [
  {
    img: "googleBg1",
    text_title: "搜索广告",
    text_intro:
      "根据商品或服务内容及特点，确定关键词，撰写刚刚内容并自主定价投放广告。",
  },
  {
    img: "googleBg2",
    text_title: "视频广告",
    text_intro:
      "广告时长不超过6s，通过广告传达令人难忘的简短信息，广泛覆盖客户并提升品牌知名度。",
  },
  {
    img: "googleBg3",
    text_title: "展示广告",
    text_intro: "基于文本、图片或视频组成，覆盖全球约90%互联网用户。",
  },
]

const bingData = [
  {
    img: "bingBg1",
    text_title: "搜索广告",
    text_intro: "协助广告主提升商品销量、潜在客户数量或网站流量。",
  },
  {
    img: "bingBg2",
    text_title: "产品广告",
    text_intro: "直接向消费者展示产品特征，销售相关商品。",
  },
  {
    img: "bingBg3",
    text_title: "动态搜索",
    text_intro:
      "抓取您的网页，并查询相关信息匹配到您的网站，无需关键字即可扩大您的覆盖面。",
  },
]
const baiduData = [
  {
    img: "baiduBg1",
    text_title: "搜索广告",
    text_intro: "根据关键词，在显眼的位置优先展示广告。",
  },
  {
    img: "baiduBg2",
    text_title: "开屏广告",
    text_intro:
      "百度系APP启动时展现的开屏广告，点击后可以跳转到广告主指定的移动页面、百度智能小程序等。",
  },
  {
    img: "baiduBg3",
    text_title: "聚屏广告",
    text_intro:
      "利用百度平台优势、数据能力、AI能力，赋能屏幕。触达消费者多场景生活时刻，实现线下广告的整合、精准程序化投放。",
  },
  {
    img: "baiduBg4",
    text_title: "品牌广告",
    text_intro:
      "位于百度搜索结果首位，以超大黄金首屏展示位置，以文字、图片、视频等多种广告形式全方位推广展示企业品牌信息，提升企业品牌推广效能。",
  },
  {
    img: "baiduBg5",
    text_title: "信息流广告",
    text_intro:
      "在百度APP、百度首页、贴吧、百度手机浏览器等平台的资讯流中穿插展现的原生广告。",
  },
]
const todayData = [
  {
    img: "todayBg1",
    text_title: "图片广告",
    text_intro: "可自由选择图片尺寸，通过在信息流中展示商品。",
  },
  {
    img: "todayBg2",
    text_title: "视频广告",
    text_intro:
      "以短视频为展现样式的广告，按推广位置不同可分为“开屏视频广告”、“信息流视频广告”、“详情页视频广告”三种，以CPM计费。",
  },
  {
    img: "todayBg3",
    text_title: "APP开屏广告",
    text_intro:
      "头条开屏、开屏加粉、头条炫屏、开屏点睛、下滑开屏、开屏入画等广告样式，精准实现广告投放千人千面，日均1千万+曝光量",
  },
  {
    img: "todayBg4",
    text_title: "详情页广告",
    text_intro:
      "在资讯详情页中出现的广告，位于资讯全文结尾的下方，可针对来源进行定向投放。",
  },
]
const tencentData = [
  {
    img: "tencentBg1",
    text_title: "腾讯新闻广告",
    text_intro:
      "出现于资讯信息流中，依用户属性、历史浏览行为、兴趣偏好等智能投放，月活跃用户2.9亿+。",
  },
  {
    img: "tencentBg2",
    text_title: "腾讯视频广告",
    text_intro: "支持多种广告素材形式，根据CPC、CPM计费，越活用户5亿+",
  },
  {
    img: "tencentBg3",
    text_title: "微信广告",
    text_intro:
      "包括朋友圈广告和公众号广告，以类似朋友原创的形式在朋友圈展示。",
  },
  {
    img: "tencentBg4",
    text_title: "QQ广告",
    text_intro:
      "支持人群属性标签、地理位置、场景定向等触达方式， 适用于电商购买、品牌活动等多种广告目标的原生社交广告。",
  },
  {
    img: "tencentBg5",
    text_title: "优量广告",
    text_intro:
      "在合作媒体上展示的广告产品，汇集10万+APP，月覆盖用户超过10亿。",
  },
  {
    img: "tencentBg6",
    text_title: "腾讯看点广告",
    text_intro:
      "跨QQ看点、QQ浏览器、看点快报三端的信息流联合投放广告形式。去重日活跃用户达2.4亿，跨端触达全年龄段用户群体，日均内容消费量达96亿。",
  },
]

const OtherData = [
  {
    img: "widerBg1",
    intros: [
      "准备工作简单：新建Facebook主页，准备就绪后可直接从主页创建广告。",
      "用户量覆盖广：月活用户数高达20亿，活跃业务主页6千万。",
      "受众定位精准：可根据用户的所在地区、年龄、性别、兴趣、人口统计数据、行为和关系网络进行定位。",
      "信息触达面广：开启后，在Facebook动态信息、桌面版右边栏、Audience Network、Instagram广告均可展示。",
    ],
  },
  {
    img: "widerBg2",
    intros: [
      "搜索服务遍布全球：搜索引擎巨头，高效帮助广告主宣传推广商品，有效触达高价值用户",
      "预算可控：提供多种广告类型，根据需求和预算灵活定制化广告。",
      "适用中小企业：智能广告助力中小企业稳步推广，机器学习针对目标量身打造及优化",
    ],
  },
  {
    img: "widerBg3",
    intros: [
      "搜索服务遍布全球：全球第二大搜索引擎，搜索请求遍布36个国家，超6亿搜索用户，美国搜索市场份额超过33%。",
      "用户超强购买力：超1/3bing网络用户家庭年收入超10万美金，超1/2用户45岁以下，其中51%为女性。",
      "按效果付费：预算上限可设，广告投入产出大幅提升。",
    ],
  },
  {
    img: "widerBg4",
    intros: [
      "生活场景全盘覆盖：每日超60亿次搜索，超1亿用户浏览信息流、800亿次定位请求。",
      "AI技术精准投放：通过用户浏览偏好，挖掘用户兴趣爱好，精准投放广告。",
      "有效控制广告成本：设置消费限额，灵活调整广告费用，基于目标优化成本。",
    ],
  },
  {
    img: "widerBg5",
    intros: [
      "用户量大：月活跃用户1.75亿，每人日均使用76分钟。",
      "个性化精准推送：根据用户阅读习惯推送相关广告，精准推送且降低了对用户的干扰。",
      "投放费用可控：高投放清晰度及透明度，真实曝光量及点击量，有效利用每一笔投资。",
    ],
  },
  {
    img: "widerBg6",
    intros: [
      "海量产品及用户：多款海量流量产品，场景全覆盖，几乎覆盖全量中国移动互联网用户。",
      "精准触达：多维智能定向，标签画像，更高转化自动匹配，有效触达目标用户。",
      "数字化营销：算法引擎加持，全链路环节解决方案。",
    ],
  },
]

const ServiceData = [
  {
    title: "开通账户",
    details: ["精编指南", "顾问沟通"],
  },
  {
    title: "定位受众",

    details: ["工具支持", "粘性巩固", "营销漏斗"],
  },
  {
    title: "创建广告",
    details: ["素材组合", "创意模板", "投放布局"],
  },
  {
    title: "效果评估",
    details: ["数据分析", "策略调整", "内容优化"],
  },
]

export default function MarketingForce(params: Ipage) {
  return (
    <>
      <div className={styles.marketingForce}>
        <Helmet title="营销力量-Yunstar" />

        {/* Google、Bing投放团队 */}
        <div id="google" />
        <div
          style={{ background: "#F9FBFE", borderTop: "1px solid transparent" }}
        >
          <Title>Google、Bing投放团队</Title>
          <ChromeIntro
            img={getImg(OtherData[1].img)}
            intros={OtherData[1].intros}
          />
          <FlexBox>
            {googleData.map(item => (
              <RenderItem
                img={getImg(item.img)}
                text_intro={item.text_intro}
                text_title={item.text_title}
                key={item.text_intro}
              />
            ))}
          </FlexBox>
          <Line />
          <ChromeIntro
            img={getImg(OtherData[2].img)}
            intros={OtherData[2].intros}
          />
          <FlexBox>
            {bingData.map(item => (
              <RenderItem
                img={getImg(item.img)}
                text_intro={item.text_intro}
                text_title={item.text_title}
                key={item.text_intro}
              />
            ))}
          </FlexBox>
        </div>

        {/* Facebook专营团队 */}
        <div id="facebook" />
        <Title>Facebook投放团队</Title>
        <ChromeIntro
          img={getImg(OtherData[0].img)}
          intros={OtherData[0].intros}
        />
        <div
          className={styles.mainType}
        >
          主要广告类型
        </div>
        <FlexBox>
          {facebookData.map(item => (
            <RenderItem
              img={getImg(item.img)}
              text_intro={item.text_intro}
              text_title={item.text_title}
              key={item.text_intro}
            />
          ))}
        </FlexBox>

        {/* Yunstar全流程Facebook广告营销赋能服务 */}

        <div className={styles.service}>
          <Row>
            <Col md={24} sm-={0} xs={0}>
              <div className={styles.service_title}>我们的服务</div>
            </Col>
            <Col md={0} sm={24} xs={24}>
              <div className={styles.service_title}>我们的服务</div>
            </Col>
          </Row>

          <ul className={styles.flex_group}>
            {ServiceData.map(item => (
              <li className={styles.flex_item} key={item.title}>
                <div className={styles.text_big}>{item.title}</div>
                <div className={styles.text_normal}>
                  {item.details.map(item => (
                    <div key={item}>{item}</div>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* 百度、头条、腾讯投放团队 */}
        <div id="baidu" />
        <Title>百度、头条、腾讯投放团队</Title>
        <ChromeIntro
          img={getImg(OtherData[3].img)}
          intros={OtherData[3].intros}
        />
        <FlexBox>
          {baiduData.map(item => (
            <RenderItem
              img={getImg(item.img)}
              text_intro={item.text_intro}
              text_title={item.text_title}
              key={item.text_intro}
            />
          ))}
        </FlexBox>
        <Line />
        <ChromeIntro
          img={getImg(OtherData[4].img)}
          intros={OtherData[4].intros}
        />
        <FlexBox>
          {todayData.map(item => (
            <RenderItem
              img={getImg(item.img)}
              text_intro={item.text_intro}
              text_title={item.text_title}
              key={item.text_intro}
            />
          ))}
        </FlexBox>
        <Line />
        <ChromeIntro
          img={getImg(OtherData[5].img)}
          intros={OtherData[5].intros}
        />
        <FlexBox>
          {tencentData.map(item => (
            <RenderItem
              img={getImg(item.img)}
              text_intro={item.text_intro}
              text_title={item.text_title}
              key={item.text_intro}
            />
          ))}
        </FlexBox>
        <Row>
          <Col md={24} sm={0} xs={0}>
            <img
              src={getImg("banner")}
              alt="footerbanner"
              style={{ marginTop: "120px", width: "100%" }}
            />
          </Col>
          <Col md={0} sm={24} xs={24}>
            <img
              src={getImg("banner_mobile")}
              alt="footerbanner"
              style={{ marginTop: "40px", width: "100%" }}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}
